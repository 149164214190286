import logo from "./Relevant.svg";
import "./App.css";

function App() {
  // Redirect immediately
  window.location.replace(
    "https://docs.google.com/forms/d/e/1FAIpQLSdbym0G0KKgmXDZ5Ec2wq0huAd_91-_RZ1kP5s_H2g19bnMoQ/viewform?pli=1"
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
